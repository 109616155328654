import React, {Component, Fragment} from 'react'
import StakeholdersModal from '../common/StakeholdersModal';
import SuccessPopup from '../common/Popups'
import { url } from '../../constants';
import moment from 'moment';
import _ from 'lodash';
import {
    PracticeActionsService,
    ReportsService,
   // WorkGroupsService
} from '../../services'

import panels from '../../constants/panels'
import { Link } from 'react-router-dom'
import etLoadReport from './ReportSetup/utils/etLoadReport'
import InspectionSummaryDisplay from './ReportSetup/DisplayComponents/InspectionSummary/InspectionSummaryDisplay'
import BMPEventSummaryComplete from './ReportSetup/Pages/BMPFlow/BMPEventSummaryComplete';
import BMPEventSummaryIncomplete from './ReportSetup/Pages/BMPFlow/BMPEventSummaryIncomplete';

export default class ViewReport extends Component {

	constructor(props){
        super(props);

        let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));
        let idreport = JSON.parse(localStorage.getItem('activeReport'));


        this.state = {
            loading: true, idreport, workUnit, phase: "InspectionSummary"
        }
    }

    componentWillMount = async () => {
		this.loadReport()
    }

    loadReport = async() => {
    	console.log("Calling loadReport");
    	let {idreport, groupsList} = this.state;

    	let loadedReportData = await ReportsService.getReport(idreport);
    	console.log("ViewReport loadedReportData:", loadedReportData);
    	let newState = await etLoadReport({loadedReportData, idreport});
    	newState.siteName = loadedReportData.siteName;
    	newState.loading = false;

    	this.setState(newState);
    }

    getMapImageCallback = (fileNames) => {
        if (!_.isEmpty(fileNames)) setTimeout(() => {
        	let newState = _.cloneDeep(this.state);
        	newState.mapImg = fileNames[0]
    		console.log("Calling getMapImageCallback with filenames", fileNames)

            this.setState(newState, () => ReportsService.saveReportSetup(this.state))
        }, 0)
    }

    handleChange = (key, val) => {
    	this.setState({[key]: val})
    }

     //Todo: make handleBMPChange its own util
    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));


    handleBMPChange = async (val, async = false, activeBMPIndex) => {
        let {loggedBMPs} = this.state;
        console.log("Calling handleBMPChange with val ",val, "loggedBMPs", loggedBMPs, "activeBMPIndex", activeBMPIndex);

        
        let cloneGroupsList = _.cloneDeep(loggedBMPs);
        cloneGroupsList[activeBMPIndex] = val;
        console.log("handleBMPChange clone list after change:", cloneGroupsList);

        let newState = {loggedBMPs: cloneGroupsList};


        if (async){
            await this.promisedSetState(newState)
            await ReportsService.saveReportSetup(this.state);

        } else {
            this.setState(newState, () => ReportsService.saveReportSetup(this.state))
        }

        console.log("state after handleBMPChange", this.state);

    }


	render = () => {
		
		if (this.state.loading) return <p class="text-center">Getting inspection data...</p>
		else { 
			let {idsite, siteName, idreport, phase} = this.state;

			if (phase == "InspectionSummary"){
				return(<>
					<InspectionSummaryDisplay
						reportState={this.state}
						handleChange={this.handleChange}
						handleBMPChange={this.handleBMPChange}
						getMapImageCallback={this.getMapImageCallback}
						headerChild={() => {return <div class="col-12 mx-auto mb-6 flexbox pt-4 border-top">
							
						 <button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#stakeholders"><i class="ti ti-share mr-1" aria-hidden="true"></i>Share With Stakeholders</button>
					</div>}}
					/>

					<StakeholdersModal
			            idsite={idsite}
			            siteName={siteName}
			            reportsData={{reportids: [idreport]}}
			            pdfMode="single"
			        />
				</>)
			} else if (phase == "BMPEventSummaryComplete"){
				return(<>

					<BMPEventSummaryComplete
						reportState={this.state}
						handleChange={this.handleChange}
						allowEditing={false}
					/>

				</>)
			} else if (phase == "BMPEventSummaryIncomplete"){
				return(<>

					<BMPEventSummaryIncomplete
						reportState={this.state}
						handleChange={this.handleChange}
						allowEditing={false}
					/>

				</>)
			}

			return (<>
				<p class="text-center">Error: The report has loaded, but the system attempted to go to the invalid page {phase}</p>
				
	        </>)
		}

	}

}