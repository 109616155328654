import React, {Fragment, useState} from 'react';
import SummarySectionDisplay from './SummarySectionDisplay';
import SummaryListDisplay from './SummaryListDisplay';
import BMPLocationDisplay from '../BMPLocationDisplay';
import LoggedBMPDisplay from '../LoggedBMPDisplay';
import PhotoModal from '../../EntryComponents/generic/PhotoModal';
import _ from 'lodash';
import { Link } from 'react-router-dom'

import {
  isMobileOnly
} from 'react-device-detect'


export default function InspectionSummaryDisplay({handleBMPChange, reportState, handleChange, headerChild, footerChild, captureMapNow, getMapImageCallback}){

	let {sitename, siteName, workUnit, flexdata, loggedBMPs, status, canEditFlexdata, canEditBMPs, idsite} = reportState;


	return(<>
		<header className="section-header mb-0">
			<h2><strong>Inspection Summary</strong></h2>
		</header>

		<div className="section pt-4">
			<div className="container px-0">

				{headerChild ? headerChild() : null}

				<div className="row">

					<div class="text-left float-left border-bottom pb-5 mb-5 col-12">
						<Link class="btn btn-sm btn-secondary" to={() => '/app/inspection-reports'}><i class="fa fa-angle-left mr-1"></i>Inspection Reports</Link>
					</div>

					{(!idsite && status == "setup") ? (isMobileOnly ? 
						<div class="col-12 border-bottom pb-5">
              <p class="lead-3 p-0 mb-4 ml-2 pl-2">{workUnit.name}</p>
              <button class="btn btn-block btn-primary" onClick={() => handleChange("phase", "ProjectInfo")}>Start Inspection</button>
	          </div>
					:
						<div className="col-12 border-bottom pb-5 flexbox">
							<p className="lead-3 p-0 mb-0 ml-2 pl-2">{workUnit.name}</p>
							<button class="btn btn-primary" onClick={() => handleChange("phase", "ProjectInfo")}>Start Inspection</button>
						</div>
          ) : null}

					<div className="col-12 mx-auto mb-6 clearfix px-0">

						<SummarySectionDisplay
							title="Site Info"
							items={[
								{label: "Construction Site Name", value: (reportState.name || reportState.siteName || reportState.sitename)},
								{label: "Site ID #", value: flexdata.officialsiteid},
								{label: "Location, Municipality, County", value: flexdata.municipality},
								{label: "Contractor/Onsite Contact", value: flexdata.onsitecontact},
								{label: "Onsite Phone/Cell", value: flexdata.onsitephone, type: "phone"}
							]}
							showEdit={canEditFlexdata}
							edit={() => handleChange("phase", "ProjectInfo")}
							status={status}
							isMobileOnly={isMobileOnly}
						/>

						<SummarySectionDisplay
							title="Inspection Info"
							items={[
								{label: "Inspection Date", value: reportState.date, type: "date"},
								{label: "Inspection Type", value: reportState.type},
								{label: "Start Time", value: reportState.date, type: "time"},
								{label: "End Time", value: flexdata.inspectionend, type: "time"},
								{label: "Name(s) of Inspectors", value: flexdata.inspectionnames},
								{label: "Inspector Phone #", value: flexdata.inspectorphone, type: "phone"},
							]}
							showEdit={canEditFlexdata}
							edit={() => handleChange("phase", "InspectionInfo")}
							status={status}
							isMobileOnly={isMobileOnly}
						/>

						<SummarySectionDisplay
							title="Weather Conditions"
							items={[
								{label: "Temperature", value: flexdata.temperature},
								{label: "Soil Moisture", value: flexdata.soilmoisture, type: "checkbox"},
								{label: "Last Rainfull Date", value: flexdata.lastrainfalldate, type: "datetime"},
								{label: "Last Rainfall Depth", value: flexdata.lastrainfalldepth}
							]}
							showEdit={canEditFlexdata}
							edit={() => handleChange("phase", "WeatherAndSoil")}
							status={status}
							isMobileOnly={isMobileOnly}
						/>

						<SummarySectionDisplay
							title="Project Phase"
							items={[
								{label: "Phase of Construction", value: flexdata.currentphase},
								{label: "Final Stabilization Date", value: flexdata.stabilizationdate, type: "date"},
								{label: "Project Schedule", value: flexdata.projectonschedule},
								{label: "Erosion Control Plan", value: flexdata.erosionplanaccessible},
								{label: "Permit Posted", value: flexdata.permitcertificateposted}
							]}
							showEdit={canEditFlexdata}
							edit={() => handleChange("phase", "ProjectPhase")}
							status={status}
							isMobileOnly={isMobileOnly}
						/>						

					</div>

				</div>



				<div className="row">

					{isMobileOnly ?
					<div className="col-12">
						<div className="col-12 pl-1 mb-2 clearfix">
							<p className="mt-4 mb-1 fs-20 float-left"><strong>Logged BMPs:</strong></p>
							{canEditFlexdata ? <button className="btn btn-xs btn-round btn-secondary fs-12 mt-4 mb-3 ml-4 px-4 float-right" onClick={() => handleChange("phase", "BMPInspection")}>LOG MORE</button> : null}
						</div>

						{idsite || siteName || sitename ? <>
							 <BMPLocationDisplay 
								bmpList={reportState.loggedBMPs || []} 
								reportState={reportState} 
								getMapImageCallback={getMapImageCallback}
								capture={reportState.captureMapNow}
								mapLoadedCallback={() => handleChange("mapCurrentlyLoaded", true)}
								mapUnloadedCallback={() => handleChange("mapCurrentlyLoaded", false)}
							/> 
							
							<button type="button" class="btn btn-round btn-small btn-primary mt-4" onClick={() => handleChange("captureMapNow", true, "async")} data-toggle="modal" data-target="#add-photos-reportViewMapPreview">
               <i className="fa fa-camera mr-2 mt-n5"></i>
                  Re-capture Map Picture
              </button>

              <button type="button" class="btn btn-round btn-small btn-primary mt-4" data-toggle="modal" data-target="#add-photos-reportViewMapPreview">
               <i className="fa fa-camera mr-2 mt-n5"></i>
                  Preview Saved Map Picture
              </button>

              <PhotoModal
              	uniqueID="reportViewMapPreview"
              	readOnly={true}
              	pictures={reportState.mapImg ? reportState.mapImg : null}
              />

						</>: null}
					</div>

					:
						<div className="col-12">
							<div className="col-12 pl-1 mb-2 clearfix">
								<p className="mt-4 mb-1 fs-20 float-left"><strong>Logged BMPs:</strong></p>
								{canEditFlexdata ? <button className="btn btn-xs btn-round btn-secondary fs-12 mt-4 mb-3 ml-4 px-4 float-right" onClick={() => handleChange("phase", "BMPInspection")}>LOG MORE</button> : null}
							</div>

							{idsite || siteName || sitename ? 
								<>
									<BMPLocationDisplay 
									bmpList={reportState.loggedBMPs || []} 
									reportState={reportState} 
									getMapImageCallback={getMapImageCallback}
									capture={reportState.captureMapNow}
									mapLoadedCallback={() => handleChange("mapCurrentlyLoaded", true)}
									mapUnloadedCallback={() => handleChange("mapCurrentlyLoaded", false)}
									/>

							<button type="button" class="btn btn-round btn-small btn-primary mt-4" onClick={() => handleChange("captureMapNow", true, "async")} data-toggle="modal" data-target="#add-photos-reportViewMapPreview">
                  Re-capture Map Picture
              </button>

              <button type="button" class="btn btn-round btn-small btn-outline-primary ml-2 mt-4" data-toggle="modal" data-target="#add-photos-reportViewMapPreview">
                  Preview Saved Map Picture
              </button>

              <PhotoModal
              	uniqueID="reportViewMapPreview"
              	readOnly={true}
              	pictures={reportState.mapImg ? [reportState.mapImg] : []}
              	directory="mapimages"
              />
								</> : null}
						</div>

					}

					

					{/*<div className="col-12 clearfix my-2 border-bottom mb-6">
						<div className="col-4 flexbox float-right">
							<label className="small strong text-brand">FILTER</label>
							<label className="lead-4 strong text-success"><i className="fa fa-check-square mr-2 text-success"></i></label>
							<label className="lead-4 strong text-warning"><i className="fa fa-exclamation-triangle mr-2 text-warning"></i></label>
							<label className="lead-4 strong text-danger"><i className="fa fa-clock-o mr-2 text-danger"></i></label>
						</div>
					</div>*/}

					<div className="col-12 mx-auto p-0 m-0 my-lg-4 pb-4 mb-4 pt-lg-4 pb-lg-7">


						{reportState.loggedBMPs.length ? reportState.loggedBMPs.map((bmp, index) => {
							return <LoggedBMPDisplay
								passedBMP={bmp}
								key={bmp.idaction}
								handleChange={handleChange}
								handleBMPChange={handleBMPChange}
								reportState={reportState}
								isMobileOnly={isMobileOnly}
							/>
						}) : <span className="text-small text-light ml-6">No BMPs logged yet</span>}

					</div>
				</div>

				<SummaryListDisplay
					title="BMPs in Good Condition"
					label="BMPs that need corrective action are shown below."
					list={_.filter(flexdata.verifiedBMPList, {status: "good"})}
					showEdit={canEditFlexdata}
					edit={() => handleChange("phase", "BMPVerification")}
					status={status}
					isMobileOnly={isMobileOnly}
					icon={"ti ti-check text-success"}
				/>

				<SummaryListDisplay
					title="BMPs Currently Not Applicable"
					label="BMPs that need corrective action are shown below."
					list={_.filter(flexdata.verifiedBMPList, {status: "na"})}
					showEdit={canEditFlexdata}
					edit={() => handleChange("phase", "BMPVerification")}
					status={status}
					isMobileOnly={isMobileOnly}
					icon={"ti ti-na text-secondary"}
				/>

				<SummarySectionDisplay
					title="Stabilization Review"
					items={[
						{label: "Temporary Stabilization", value: flexdata.constructionstabilized},
						{label: "Permanent Stabilization", value: flexdata.areasstabilized},
						{label: "Final Stabilization", value: flexdata.sedimentcontrolsremoved},
					]}
					showEdit={canEditFlexdata}
					edit={() => handleChange("phase", "StabilizationReview")}
					status={status}
					isMobileOnly={isMobileOnly}
				/>

				<SummarySectionDisplay
					title="Plan Review"
					items={[
						//{label: "BMPs on Plan", value: flexdata.allbmpsfunctional},
						{label: "Plan Reflection", value: flexdata.planaddressesallissues},
						{label: "Any suggested revisions?", value: [flexdata.planreviewrevisions, flexdata.planreviewnotes], type: ["checkbox", undefined], customEmptyState: (flexdata.planaddressesallissues ? "None" : undefined)},
					]}
					showEdit={canEditFlexdata}
					edit={() => handleChange("phase", "PlanReview")}
					status={status}
					isMobileOnly={isMobileOnly}
				/>

				<SummaryListDisplay
					title="Maintenance Crew for Corrective Actions"
					label="Text messages will be sent to these employees."
					labelClassName="small lh-1 ml-6"
					list={_.filter(reportState.employeesList, {selected: true})}
					showEdit={canEditFlexdata}
					edit={() => handleChange("phase", "InspectionWrapUp")}
					status={status}
					isMobileOnly={isMobileOnly}
					useColumns={true}
				/>


				{footerChild ? footerChild() : null}

			</div>

		</div>
	</>);
}