import React, {Fragment, useState, useEffect} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import determineBMPStatus from '../../utils/determineBMPStatus'
/*import BMPEntry from '../../EntryComponents/BMPEntry'
import EmployeeEntry from '../../EntryComponents/EmployeeEntry'*/

import Document from '../../EntryComponents/generic/Document';
import DisplayDocumentation from '../../DisplayComponents/generic/DisplayDocumentation';
import RequiredCorrectiveActions from '../../EntryComponents/RequiredCorrectiveActions';
import BMPLocationDisplay from '../../DisplayComponents/BMPLocationDisplay';
import SelectedCAsDisplay from '../../DisplayComponents/SelectedCAsDisplay';

export default function BMPEventSummaryComplete(props) {

	let {handleChange, handleBMPChange, cancel, completeReport, reportState, saveReportForLater} = props;
	let {sendingReport, loggedBMPs, activeBMPIndex, status} = reportState;

	let [activeBMP, setActiveBMPDirect] = useState(_.cloneDeep(loggedBMPs[activeBMPIndex]))
	let [refreshPage, setRefreshPage] = useState(false);
	let [validCorrections, setValidCorrections] = useState(true);
	let [showCADocumentationEntry, setShowCADocumentationEntry] = useState(false);
	let [showCADocumentationDisplay, setCADocumentationDisplay] = useState(false);


	useEffect(() => {
		let status = activeBMPStatus();
		let bmp = _.cloneDeep(activeBMP);
		bmp.correctionDocumentation.status = status;
		setActiveBMP(bmp);
	}, [])

	let activeBMPStatus = () => {
		let status = determineBMPStatus(activeBMP, reportState);
		if (status !== "Complete"){
			handleChange("phase", "BMPEventSummaryIncomplete");
		} else {
			return status;
		}
	}

	 let setActiveBMP = (bmp) => {
        console.log("Calling setActiveBMP");
        if (handleBMPChange) handleBMPChange(bmp)
        setActiveBMPDirect(bmp);
    }


	return(<>

	<InspectionWrapper
          title="Logged BMP"
          navButtons={[
            {
              className: "btn-secondary", 
              onClick: () => handleChange('phase', 'prev'),
              label: <><i className="fa fa-angle-left mr-1"></i> BACK</>,
              hideConditions: (status == "finished")
            },
            {
              className: "btn-primary" + (status == "finished" ? " mr-auto" : ""),
              onClick: () => handleChange('phase', ((status != "finished" && status != "open") ? "LoggedEventsList" : "InspectionSummary")),
              label: <>DONE <i class="fa fa-angle-right ml-1"></i></>
            },

          ]}
          isBMPFlow={true}
        >

				<div class="col-12 mb-4 p-3 ml-2 border-bottom">
					<p class="mb-1 fs-20"><i class="fa fa-cube px-2" aria-hidden="true"></i><strong>{activeBMP.type}: {activeBMP.name}</strong></p>
				</div>        

				<div class="col-12">
					<BMPLocationDisplay bmpList={[activeBMP]} reportState={reportState} />
				</div>

				<DisplayDocumentation
					includeInitials={false}
          obj={activeBMP.conditionDocumentation}
          label={"BMP Conditions"}
				/>

				<div class="col-12 mt-3 px-4">
				    <p class="mb-1 fs-17 mb-4 border-bottom"><strong>Required Corrective Actions:</strong></p>

				    <div class="form-group ml-6 pb-4 border-bottom">
				        <div class="custom-controls-stacked pb-2">

				        	<SelectedCAsDisplay
				        		passedCas={activeBMP.cas}
				        		useListView={true}
				        	/> 

				        </div>

				    </div>                              

				</div>

				<div>
					
					<DisplayDocumentation
						includeInitials={true}
            obj={activeBMP.correctionDocumentation}
            label={"Corrective Action:"}
					/>

				</div>

	</InspectionWrapper>
  
	</>)

}