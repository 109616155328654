import React, {Component, Fragment} from 'react'
import SuccessPopup from '../../common/Popups';
import { url } from '../../../constants'
import {
    LibraryService,
    ApiService,
    ReportsService,
    WorkGroupsService
} from '../../../services'
import parseDateStringIntoDateObj from '../../../utils/parseDateStringIntoDateObj'
import alphanumericSort from "../../../utils/alphanumericSort"
import etLoadReport from './utils/etLoadReport'
import processAutoBMPs from './utils/processAutoBMPs'
import pages from './Pages/pages'
import _ from 'lodash'
import { Beforeunload } from 'react-beforeunload';

export default class ReportSetup extends Component {

	constructor(props){
        super(props);

        let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));
        let initialReportState = "InspectionSummary";

        this.notesAndStatusObj = {notes: "", status: null}

        this.state = {
        	name: "", 
        	date: new Date(),
        	idsite: null,
        	sitesList: [],
        	employeesList: [],
            createdby: ApiService.getJWTEmail(),

            type: null,
            constructionphase: "", 
            scheduleconfirmation: "",
            soilmoisture: "",
            lastprecipitation: "",
        	groupsList: [{name: "", entries: [{name: "", oppositeRelations: []}]}],
        	workUnit, initialReportState,
            phase: initialReportState,
            showCancelPopup: false,
            actionsList: null,
            submitDisabled: false,
            sendingReport: false,
            employeeIDs: [],
            idreport: null,
            status: "setup",
            loadedReportData: false,
            anyActionsSelected: false, 
            showFinalizePopup: false,
            showClosePopup: false,

            showPreviousReportDatePicker: false,
            previousReportDate: null,
            prevPageState: {},

            loggedBMPs: [],
            autoBMPsList: [],
            currentBMPCorrected: false,

            activeGroupIndex: 0,
            activeEntryIndex: 0,
            activeBMPIndex: 0,
            employeeFilter: "",

            activeActionGroup: 0,
            activeActionEntry: 0,

            canEditFlexdata: true,
            canEditBMPs: true,
            captureMapNow: false,

            mapCenter: {lat: 42.836626603240994, lng: -88.74254147983879},
            mapZoom: 18.1118915215556,
            mapImg: null,

            constructionPhases: [],

            flexdata: { //Important: Don't duplicate any keys between flexdata and the rest of the state
                phase: initialReportState,
                phaseHistory: [initialReportState],

                officialsiteid: "", 
                municipality: "", 
                onsitecontact: workUnit.name, 
                onsitephone: "", 
                inspectionnames: "", 
                inspectorphone: "", 
                temperature: "", 
                soilmoisture: "", 
                lastrainfalldate: null, 
                lastrainfalldepth: "",

                currentphase: "",
                stabilizationdate: null,
                projectonschedule: "",
                erosionplanaccessible: "",
                permitcertificateposted: "",

                verifiedBMPList: [],
                constructionstabilized: "",
                areasstabilized: "",
                sedimentcontrolsremoved: "",
                allbmpsfunctional: "",
                planaddressesallissues: "",
                planreviewrevisions: "",
                planreviewnotes: "",
                inspectionend: null,
            },

            mapCurrentlyLoaded: false,
            initialLoadFinished: false
        }
    }

    scrollToTop = () => {
        let mainContainer = document.querySelector("#main-container");
        mainContainer.scroll({top: 0});
    }

    onPhaseChange = (phase) => {
        console.log("onPhaseChange passed phase:", phase)
        this.scrollToTop();
        this.autoSave("onPhaseChange");
        if (phase == "InspectionSummary"){
            setTimeout(() => {
                //this.setState({captureMapNow: true})
            }, 0)
        }
    }

    autoSave = async (origin = "unmount") => {
        console.log("Calling autoSave from " + origin);
        await this.saveReport(null);
    }

    componentDidUpdate = async(prevProps, prevState) => {
        let prevPhase = prevState.flexdata.phase;
        let prevIdsite = prevState.idsite;
        let {flexdata, idsite, idreport, workUnit, employeeNames, employeesList} = this.state;
        let {phase, inspectionnames} = flexdata

       /* console.log("componentDidUpdate showClosePopup debug", 

            _.reduce(this.state, function(result, value, key) {
                    return _.isEqual(value, prevState[key]) ?
                        result : result.concat(key);
                }, [])

        ) */

        //console.log("componentDidUpdate phaseControl", "phase:", phase, "prevPhase", prevPhase, "phaseHistory:", flexdata.phaseHistory);
        //Page control updates
        if (phase == "prev"){
          // console.log("componentDidUpdate phaseControl", "going to prev phase");
           //console.log("componentDidUpdate phaseControl", "prev phasechange phaseHistory:", flexdata.phaseHistory);
           let newPhase = flexdata.phaseHistory[flexdata.phaseHistory.length-2];
           //console.log("componentDidUpdate phaseControl", "prev phase to go to: " + newPhase);
            if (newPhase != prevPhase){
                flexdata.phaseHistory.pop();
                flexdata.phase = flexdata.phaseHistory[flexdata.phaseHistory.length-1];
                await this.setState({flexdata}, this.onPhaseChange(newPhase))
            }
        } else if (prevPhase != phase && phase != flexdata.phaseHistory[flexdata.phaseHistory.length-1]){
            //console.log("componentDidUpdate phaseControl", "phase changed to non-prev phase, adding new phase to history:", flexdata.phaseHistory);
            flexdata.phaseHistory.push(phase);
            //console.log("componentDidUpdate phaseControl", "new phase history:", flexdata.phaseHistory);
            await this.setState({flexdata}, this.onPhaseChange(phase))
        } else {
            //console.log("componentDidUpdate phaseControl", "no phase changes detected")
        }

        //console.log("pullInPastReportDataBySite prevIdsite:", prevIdsite, "idsite:", idsite, "comparison result:", (prevIdsite !== idsite));

        //State updates to pull in data by site
        if (prevIdsite !== idsite){
            let prevReportData = await ReportsService.pullInPastReportDataBySite(idsite, workUnit.idworkgroup);
            //console.log("pullInPastReportDataBySite componentDidUpdate prevReportData:", prevReportData);

            //TODO: Centralize these type constants somehow and make this part less... bad.                
            if (prevReportData.flexdata.lastrainfalldate) prevReportData.flexdata.lastrainfalldate = parseDateStringIntoDateObj(prevReportData.flexdata.lastrainfalldate);
            if (prevReportData.flexdata.stabilizationdate) prevReportData.flexdata.stabilizationdate = parseDateStringIntoDateObj(prevReportData.flexdata.stabilizationdate);

            let flexDataKeys = Object.keys(this.state.flexdata)
            //console.log("pullInPastReportDataBySite flexDataKeys:", flexDataKeys);
            //console.log("pullInPastReportDataBySite prevReportData flexdata:", prevReportData.flexdata);

            for (var i = 0; i < flexDataKeys.length; i++){
                let key = flexDataKeys[i];
                //console.log("pullInPastReportDataBySite flexdata key at index "+i+":", key);
                //console.log("pullInPastReportDataBySite value of prevReportData["+key+"]:", prevReportData.flexdata[key]);
                //console.log("pullInPastReportDataBySite value of this.state["+key+":", this.state.flexdata[key]);

                if (prevReportData.flexdata[key] === undefined){
                    //console.log("pullInPastReportDataBySite Adding key " + key + " to newState");
                    if (key == "lastrainfalldate" || key == "stabilizationdate"){ 
                        prevReportData.flexdata[key] = this.state.flexdata[key];
                    }
                    prevReportData.flexdata[key] = this.state.flexdata[key];
                }
            }

            prevReportData.groupsList = await this.initiateGroupsList();

            //console.log("pullInPastReportDataBySite final prevReportData:", prevReportData.flexdata);

            this.setState(prevReportData);
        }
        

        processAutoBMPs(this.state, prevState, this.handleChange);

        if (this.state.status != prevState.status){
            let status = this.state.status;
            let canEditFlexdata = (status != "open" && status != "closed")
            let canEditBMPs = (status != "closed")
            this.setState({canEditBMPs, canEditFlexdata}, () => {
                console.log("edit permissions updated to: flexdata:", canEditFlexdata, "bmps:", canEditBMPs)
            })
        }

        /*console.log("componentDidUpdate inspectionnames", 
            JSON.stringify(inspectionnames), 
            JSON.stringify(prevState.flexdata.inspectionnames), 
            JSON.stringify(inspectionnames) != JSON.stringify(prevState.flexdata.inspectionnames))*/
        if (JSON.stringify(inspectionnames) != JSON.stringify(prevState.flexdata.inspectionnames)) {
            //console.log("componentDidUpdate inspectionnames changed")
            if (inspectionnames.length > 0){
                //console.log("componentDidUpdate inspectionnames selected")
                let index = employeeNames.indexOf(inspectionnames.split(',')[0]);
                //console.log("componentDidUpdate inspectionnames index:", index);
                if (employeesList[index] && employeesList[index].phonenumber){
                    this.handleChange('inspectorphone', employeesList[index].phonenumber)
                }
            }
        }
    }

    componentWillMount = async () => {

    	let {workUnit, createdby, flexdata} = this.state;


        let fetchedAutoBMPsList = await LibraryService.getLibrary(-1, "practice", true);
        console.log("processAutoBMPs fetchedAutoBMPsList:", fetchedAutoBMPsList);
        let autoBMPsList = _.orderBy(_.cloneDeep(fetchedAutoBMPsList[0].entries), ['idpractice'], ['desc']); //Ensures algorithms in utils/processAutoBMPs to align practice ids with indices are consistent
        console.log("processAutoBMPs post-clone autoBMPsList:", autoBMPsList);
        for (var i = 0; i < autoBMPsList.length; i++){
            for (var j = 0; j < autoBMPsList[i].cas.length; j++){
                autoBMPsList[i].cas[j].status = true;
            }
        }

    	let employeesList = await WorkGroupsService.getEmployees(workUnit.idworkgroup);
        employeesList = this.ensureCreatorSelected(employeesList);

        let employeeIDs = employeesList.map(({idemployee}) => {return idemployee});
        let employeeNames = employeesList.map(({idemployee, name}) => {return (name ? name : idemployee)});
        let sitesList = await WorkGroupsService.getWorkGroupSites(workUnit.idworkgroup);
        console.log("componentWillMount sitesList:", sitesList);
        let loadedReportData = false;

        let newState = {employeesList, employeeIDs, employeeNames, sitesList, loadedReportData}

        if (localStorage.getItem("activeReportToEdit")){
            console.log("activeReportToEdit:", localStorage.getItem("activeReportToEdit"))

            let {idreport, status} = JSON.parse(localStorage.getItem("activeReportToEdit"));
            this.setState({status})
            console.log("loading activeReportToEdit with idreport", idreport);
            loadedReportData = await ReportsService.getReport(idreport);
            console.log("activeReportToEdit loadedReport data:", loadedReportData);
            let loadReportIntoNewState = await etLoadReport({employeesList, employeeIDs, flexdata, sitesList, loadedReportData, idreport, includeActions: true});
            console.log("activeReportToEdit loadReportIntoNewState:", loadReportIntoNewState);

            loadReportIntoNewState.loadedReport = true;

            if (loadedReportData.status == "open"){
                loadReportIntoNewState.flexdata.phase = "InspectionSummary";
            }

            newState = _.cloneDeep(loadReportIntoNewState);
            console.log("activeReportToEdit newState in localStorage if statement:", newState);

        } else {
            newState.groupsList = await this.initiateGroupsList();
        }

        newState.autoBMPsList = autoBMPsList
        newState.initialLoadFinished = true;
        newState.employeeNames = employeeNames;

        if (!newState.phase || newState.phase == "InspectionSummary"){
            setTimeout(() => {
                //this.setState({captureMapNow: true});
            }, 0)
        }

        console.log("activeReportToEdit newState:", newState);
    	this.setState(newState)
    }

    initiateGroupsList = async () => {
        let {workUnit, idsite} = this.state;

        let groupsList = await LibraryService.getLibrary(workUnit.idworkgroup, "practice", true, idsite);
        console.log("initiateGroupsList groupsList:", groupsList);

        return groupsList
    }

    componentWillUnmount = () => {
        this.autoSave("componentWillUnmount");
    }

    getNumSelected = (list) => {
    	let numSelected = 0;
    	for (var i = 0; i < list.length; i++){
    		if (list[i].selected){
    			numSelected++;
    		}
    	}

    	return numSelected;
    }

    setSelectedSite = async (idsite) => {

        let {sitesList} = this.state;
        let newState = {}

        for (var i = 0; i < sitesList.length; i++){
            if (sitesList[i].idsite == idsite){
                newState.name = sitesList[i].name;
            }
        }
        newState.idsite = idsite;
  
        this.setState(newState);
    }

    handleChange = async (key, val, param) => {
        console.log("handleChange key:", key, "val:", val, "param:", param);

        let flexDataKeys = Object.keys(this.state.flexdata)

        if (flexDataKeys.indexOf(key) != -1){
            let flexdata = _.cloneDeep(this.state.flexdata);
            flexdata[key] = val;
            this.handleChange("flexdata", flexdata, param);
        } else {

            if (param === undefined){
                this.setState({[key]: val});
            }

            else if (param == "async"){
                console.log("promisified handleChange")
                return await this.promisedSetState({[key]: val});
            }
        }
    }

    //Todo: make handleBMPChange its own util
    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));


    handleBMPChange = async (val, async = false) => {
        console.log("Calling handleBMPChange with val ",val);
        let {loggedBMPs, activeBMPIndex} = this.state;
        
        let cloneGroupsList = _.cloneDeep(loggedBMPs);
        cloneGroupsList[activeBMPIndex] = val;
        console.log("handleBMPChange clone list after change:", cloneGroupsList);

        let newState = {loggedBMPs: cloneGroupsList};

        if (async){
            await this.promisedSetState(newState)
        } else {
            this.setState(newState)
        }

    }

    /*handleCAChange = (key, val) => {
        let {groupsList, activeActionGroup, activeActionEntry} = this.state;
        let cloneGroupsList = _.cloneDeep(groupsList);
        cloneGroupsList[activeGroupIndex].entries[activeEntryIndex][key] = val;
        this.setState({groupsList: cloneGroupsList})
    }*/

    getMapImageCallback = (fileNames) => {
        console.log("Calling getMapImageCallback with filenames", fileNames)
        if (!_.isEmpty(fileNames)) setTimeout(() => 
            this.handleChange('mapImg', fileNames[0])
        , 0)
    }

    handleRadioChange = (key, val) => {
        this.handleNotesStatusChange(key, val, "status")
    }

    handleNotesChange = (key, val) => {
        this.handleNotesStatusChange(key, val, "notes")
    }

    handleNotesStatusChange = (statekey, val, objkey) =>{
        let cloneprev = _.cloneDeep(this.state[statekey])
        if (!cloneprev) cloneprev = _.cloneDeep(this.notesAndStatusObj);
        cloneprev[objkey] = val;
        this.setState({[statekey]: cloneprev});
    }

    setActionstatus = (groupIndex, entryIndex, val) => {
        let {actionsList} = this.state;
        actionsList[groupIndex].entries[entryIndex].status = val;
        let anyActionsSelected = true;
        this.setState({actionsList, anyActionsSelected});
    }

    cancelReport = () => {
        this.setState({showCancelPopup: true})
    }

    cancelBMPFlow = () => {
        this.setState({showCancelBMPPopup: true});
    }

    finishCancelBMP = () => {
        let {loggedBMPs, flexdata} = _.cloneDeep(this.state);

        loggedBMPs.pop();
        flexdata.phase = "BMPInspection"

        this.setState({loggedBMPs, flexdata, showCancelBMPPopup: false});

    }

    ensureCreatorSelected = (employeesList, createdby) => {
        if (employeesList == undefined) employeesList = _.cloneDeep(this.state.employeesList);
        if (createdby == undefined) createdby = this.state.createdby;

        for (var i = 0; i < employeesList.length; i++){
            if (employeesList[i].email == createdby){
                employeesList[i].selected = true;
            }
        }

        return employeesList;
    }

    returnToCreate = () => {
        this.setState({phase: "InspectionStart"})
    }

    saveReport = async (redirectPage = "inspection-reports") => {
        let {status, phase} = this.state;
        this.setState({sendingReport: true}, async () => {

            let res = await ReportsService.saveReportSetup(this.state);

            if (res.success){
                 localStorage.setItem("activeReportToEdit", JSON.stringify({idreport: res.idreport, status: this.state.status}));
                if (redirectPage === null) {
                    this.setState({sendingReport: false, idreport: res.idreport});
                } else {
                    this.props.history.push('/app/'+redirectPage);
                }
            } else {
                this.setState({sendingReport: false});
                alert("Error saving report");
            }
        });
    }

    sendSMS = async() => {
        await ReportsService.startReport(this.state.idreport);
        //this.setState({showFinalizePopup: true});
    }


    closeReport = async() => {
        await ReportsService.endReport(this.state.idreport, this.state.mapImg);
        this.props.history.push('/app/inspection-reports');
    }

    render = () => {
    	let {status, flexdata, actionsList, notes, sendingReport, initialLoadFinished, initialReportState} = this.state;
        let {phase} = flexdata;
        let content = null;
        let page = pages.get(phase);
        console.log("Page in render:", page);

        let loadingText = (status == "setup" ? "Setting up your inspection..." : "Getting inspection data...")

        if (!initialLoadFinished) return <p class="text-center">{loadingText}</p>;

        return(<div>

            <Beforeunload onBeforeunload={async() => await this.autoSave("onBeforeunload")} />
          
            <page.JSX
                reportState = {this.state}
                handleChange= {this.handleChange}
                getNumSelected = {this.getNumSelected}
                setSelectedPractice = {this.setSelectedPractice}
                setSelectedEmployee = {this.setSelectedEmployee}
                setSelectedSite = {this.setSelectedSite}
                cancel={this.cancelReport}
                completeReport = {this.completeReport}
                saveReportForLater = {this.saveReport}
                createdby = {this.state.createdby}
                actionsList = {actionsList}
                setActionstatus = {this.setActionstatus}
                returnToCreate = {this.returnToCreate}
                finishReportSetup = {(redirectPage) => this.saveReport(redirectPage)}
                notes = {notes}
                updateNotes = {this.updateNotes}
                sendingReport={sendingReport}
                startDate={this.state.startDate}
                setPage={(phase) => this.setState(phase)}
                handleRadioChange = {this.handleRadioChange}
                handleBMPChange={this.handleBMPChange}
                cancelBMPFlow={this.cancelBMPFlow}
                sendSMS={this.sendSMS}
                allowEditing={true}
                getMapImageCallback={this.getMapImageCallback}
            />

            {phase !== initialReportState ? 
                <div class="col-lg-10 mx-auto my-0 flexbox">
                    <button className="btn btn-secondary my-0 ml-auto mr-9" onClick={() => this.handleChange("phase", initialReportState)}>HOME</button>
                </div>
            : null}

             <SuccessPopup
                popupId="cancelPopup"
                style="warning"
                title="Confirm"
                message="Really exit report creation?"
                buttonLabel="Yes"
                trigger={this.state.showCancelPopup}
                callback={() =>  {this.props.history.goBack();}}
                useButton = {true}
                closeCallback={() => this.setState({'showCancelPopup': false})}
                />

            <SuccessPopup
                popupId="cancelBMPPopup"
                style="warning"
                title="Confirm"
                message="Really exit BMP flow?"
                buttonLabel="Yes"
                trigger={this.state.showCancelBMPPopup}
                callback={this.finishCancelBMP}
                useButton = {true}
                closeCallback={() => this.setState({'showCancelBMPPopup': false})}
                />

             <SuccessPopup
                trigger={this.state.showFinalizePopup}
                popupId="inspectionCouldBeStarted"
                style="warning"
                useButton={true}
                title={"Complete inspection"}
                message={"Are you ready to complete the inspection and notify crew?"}
                buttonLabel="Yes"
                useCancelButton={true}
                callback={() => {
                    this.sendSMS();
                    this.setState({showFinalizePopup: false, showFinalizeSuccessPopup: true})
                }}
                closeCallback={() => {
                    this.setState({showFinalizePopup: false})
                }}
            />

            <SuccessPopup
                trigger={this.state.showFinalizeSuccessPopup}
                popupId="inspectionHasBeenStarted"
                style="warning"
                useButton={false}
                title={"Inspection completed"}
                message={"Messages have been sent to your crew alerting them to maintenance needs."}
                callback={() => {
                    setTimeout(() => 
                        this.setState({showFinalizeSuccessPopup: false}, () => {
                            this.props.history.push('/app/inspection-reports');
                        })
                    , 5000)
                }}
                closeCallback={() => {
                    this.setState({showFinalizeSuccessPopup: false})
                }}
            />

            <SuccessPopup
                trigger={this.state.showClosePopup}
                popupId="reallyCloseInspection"
                style="warning"
                useButton={true}
                title={"Really close report?"}
                message={"You won't be able to log any more corrective actions after this point."}
                buttonLabel="Yes"
                callback={this.closeReport}
                closeCallback={() => {
                    this.setState({showClosePopup: false})
                }}
            />

        </div>)
    }
}