import _ from 'lodash';

export function etchFormatGroupsForFrontEnd(entries) {

	console.log("etchFormatGroupsForFrontEnd entries:", entries);


	let groups = [];
	let uniqueGroups = [];

	if (!_.isEmpty(entries)){
		for (var i = 0; i < entries.length; i++){

			let entry = _.cloneDeep(entries[i])

		    let groupIndex = uniqueGroups.indexOf(entry.type);

		    if (groupIndex == -1){
		      uniqueGroups.push(entry.type);
		      groups.push({name: entry.type, entries: [entry], filter: ""});
		    } else {
		      groups[groupIndex].entries.push(entry);
		    }

		}
	}

	console.log("etchFormatGroupsForFrontEnd toReturn:", groups);

	return _.cloneDeep(groups);
}